import React, { useRef, useEffect } from "react";
import classes from "./Most.module.scss";
import img1 from "../../../assets/img/assets/3/1.png";
import img2 from "../../../assets/img/assets/3/2.png";
import img3 from "../../../assets/img/assets/3/3.png";
import img4 from "../../../assets/img/assets/3/4.png";
import img5 from "../../../assets/img/assets/3/5.png";
import img6 from "../../../assets/img/assets/3/6.png";
import img7 from "../../../assets/img/assets/3/7.png";
import img8 from "../../../assets/img/assets/3/8.png";
import img9 from "../../../assets/img/assets/3/9.png";
import img10 from "../../../assets/img/assets/3/10.png";
import img11 from "../../../assets/img/assets/3/11.png";
import img12 from "../../../assets/img/assets/3/12.png";
import img13 from "../../../assets/img/assets/3/13.png";
import img14 from "../../../assets/img/assets/3/14.png";
import img15 from "../../../assets/img/assets/3/15.png";
import img16 from "../../../assets/img/assets/3/16.png";
import img17 from "../../../assets/img/assets/3/17.png";
import img18 from "../../../assets/img/assets/3/18.png";
import img from "../../../assets/img/assets/3/img.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Most = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.most, "pt"].join(" ")} ref={bodyRef}>
            <div className={[classes.mostBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.mostRow, ""].join(" ")}>
                        <div className={[classes.mostRowLeft, ""].join(" ")}>
                            <h2 className={[classes.mostRowLeftTitle, "font-40"].join(" ")}>
                                {t('assets.most.title')}
                            </h2>
                            <div className={[classes.mostRowLeftTable, ""].join(" ")}>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img1} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>BTC\USD</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img2} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>SPX</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img3} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>ETH\USD</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img4} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>NDX</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img5} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>BTC\ETH</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img6} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>AAPL</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img7} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>BTC\USDT</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img8} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>TSLA</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img9} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>ETH\USDT</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img10} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>JNJ</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img11} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>ADA\USDT</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img12} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>SBUX</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img13} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>DOGE\USDT</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img14} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>Dow Jones</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img15} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>XRP\USDT</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img16} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>NKE</p>
                                    </div>
                                </div>
                                <div className={[classes.mostRowLeftTableRow, ""].join(" ")}>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img17} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>XRP\BTC</p>
                                    </div>
                                    <div className={[classes.mostRowLeftTableRowItem, ""].join(" ")}>
                                        <img src={img18} alt="The Most Popular Pairs On White Advisory" />
                                        <p className='font-16'>YHOO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.mostRowRight, ""].join(" ")}
                            ref={cardRef_01}
                        >
                            <img src={img} alt="The Most Popular Pairs On White Advisory" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Most;
