import React, { useRef, useEffect } from "react";
import classes from './StartYour.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image_01 from '../../../assets/img/HomePage/StartYour/image_01.png';
import image_02 from '../../../assets/img/HomePage/StartYour/image_02.png';
import image_03 from '../../../assets/img/HomePage/StartYour/image_03.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const StartYour = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const steps = [
        {
            ref: cardRef_01,
            image: image_01,
            title: 'home.start.card_01.title',
            text: 'home.start.card_01.text',
        },
        {
            ref: cardRef_02,
            image: image_02,
            title: 'home.start.card_02.title',
            text: 'home.start.card_02.text',
        },
        {
            ref: cardRef_03,
            image: image_03,
            title: 'home.start.card_03.title',
            text: 'home.start.card_03.text',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(cardRef_02.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    y: 180,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.startYour}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('home.start.title')}
                </h2>
                <div className={classes.steps}>
                    {steps.map((step, index) =>
                        <div ref={step.ref} key={index} className={classes.step}>
                            <img className={classes.stepImage} src={step.image} alt='' />
                            <h4 className={[classes.stepTitle, 'font-20-md6'].join(' ')}>
                                {t(step.title)}
                            </h4>
                            <p className={[classes.stepText, 'font-16'].join(' ')}>
                                {t(step.text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn.trade')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default StartYour;
