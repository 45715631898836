import React, { useEffect, useRef } from "react";
import classes from './WideBonus.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import line from '../../../assets/img/HomePage/WideBonus/line.png';
import money from '../../../assets/img/HomePage/WideBonus/money.png';
import pig from '../../../assets/img/HomePage/WideBonus/pig.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const WideBonus = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const moneyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(moneyRef.current, {
                    y: -100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.wideBonus}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('home.wide_bonus.title')}
                    </h2>
                    <div className={classes.cards}>
                        <div className={classes.card}>
                            <span className="font-20-md6">
                                {t('home.wide_bonus.welcome_bonus')}
                            </span>
                        </div>
                        <div className={classes.card}>
                            <span className="font-20-md6">
                                {t('home.wide_bonus.deposit_bonus')}
                            </span>
                        </div>
                        <div className={classes.card}>
                            <span className="font-20-md6">
                                {t('home.wide_bonus.loyalty_rewards')}
                            </span>
                        </div>
                        <div className={classes.card}>
                            <span className="font-20-md6">
                                {t('home.wide_bonus.referral_bonus')}
                            </span>
                        </div>
                    </div>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        {t('home.wide_bonus.text')}
                    </p>
                    <div className={classes.btn}>
                        <Button secondary>
                            {t('btn.learn_more')}
                        </Button>
                    </div>
                </div>
                <img className={classes.line} src={line} alt='' />
                <img ref={moneyRef} className={classes.money} src={money} alt='' />
                <img className={classes.pig} src={pig} alt='' />
            </div>
        </section>
    );
};

export default WideBonus;
