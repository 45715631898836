import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/BonusesComponents/Banner/Banner";
import Types from "../../components/BonusesComponents/Types/Types";
import Requirements from "../../components/BonusesComponents/Requirements/Requirements";
import More from "../../components/BonusesComponents/More/More";
const BonusesPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Bonuses system</title>
        <meta name="title" content="Increase your trading opportunities on White Advisory with a diverse bonus system for both experienced and novice traders." />
        <meta property="og:title" content="Increase your trading opportunities on White Advisory with a diverse bonus system for both experienced and novice traders." />
        <meta property="twitter:title" content="Increase your trading opportunities on White Advisory with a diverse bonus system for both experienced and novice traders." />

        <meta name="description" content="Take advantage of White Advisory's opportunities to earn referral bonuses, loyalty bonuses, contest entry, and more to enhance your trading experience." />
        <meta property="og:description" content="Take advantage of White Advisory's opportunities to earn referral bonuses, loyalty bonuses, contest entry, and more to enhance your trading experience." />
        <meta property="twitter:description" content="Take advantage of White Advisory's opportunities to earn referral bonuses, loyalty bonuses, contest entry, and more to enhance your trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Types />
        <Requirements />
        <More />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default BonusesPage;
