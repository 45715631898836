import React from "react";
import classes from './Transparency.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/SecurityPage/Transparency/icon_01.svg';
import icon_02 from '../../../assets/img/SecurityPage/Transparency/icon_02.svg';
import icon_03 from '../../../assets/img/SecurityPage/Transparency/icon_03.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Transparency = () => {
    const { t } = useTranslation();
    const cards = [
        {
            icon: icon_01,
            title: 'security.transp.card_01.title',
            text: 'security.transp.card_01.text',
        },
        {
            icon: icon_02,
            title: 'security.transp.card_02.title',
            text: 'security.transp.card_02.text',
        },
        {
            icon: icon_03,
            title: 'security.transp.card_03.title',
            text: 'security.transp.card_03.text',
        },
    ];
    return (
        <section className="mt">
            <div className={classes.transparencyWrap}>
                <div className="container">
                    <div className={classes.transparency}>
                        <h2 className={[classes.title, 'font-40'].join(' ')}>
                            {t('security.transp.title')}
                        </h2>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t('security.transp.text')}
                        </p>
                        <div className={classes.cards}>
                            {cards.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <img className={classes.cardIcon} src={card.icon} alt='' />
                                    <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                        {t(card.title)}
                                    </h4>
                                    <p className={[classes.cardText, 'font-16'].join(' ')}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                        <div className={classes.swiperWrap}>
                            <Swiper
                                modules={[Pagination]}
                                className="securityTransSwiper"
                                spaceBetween={20}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                            >
                                {cards.map((card, index) =>
                                    <SwiperSlide key={index} className="securityTransSwiperSlide">
                                        <div className={classes.card}>
                                            <img className={classes.cardIcon} src={card.icon} alt='' />
                                            <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                                {t(card.title)}
                                            </h4>
                                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                                {t(card.text)}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Transparency;
