import React, { useEffect, useRef } from "react";
import classes from './TradeAssets.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/trade_assets.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const TradeAssets = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(imageRef.current, {
                    scale: 1.3,
                }, {
                    scale: 1,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.tradeAssets}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('home.trade_assets.title')}
                    </h2>
                    <p className={[classes.text, 'font-16'].join(' ')}>
                        {t('home.trade_assets.text')}
                    </p>
                    <div className={classes.cols}>
                        <div className={classes.col}>
                            <p className={[classes.colText, 'font-20-md6'].join(' ')}>
                                {t('home.trade_assets.cryptocurrencies')}
                            </p>
                            <ul className="font-16">
                                <li>
                                    BTC/USD
                                </li>
                                <li>
                                    ETH/USD
                                </li>
                                <li>
                                    BTC/ETH
                                </li>
                                <li>
                                    BTC/USDT
                                </li>
                                <li>
                                    ETH/USDT
                                </li>
                            </ul>
                        </div>
                        <div className={classes.col}>
                            <p className={[classes.colText, 'font-20-md6'].join(' ')}>
                                {t('home.trade_assets.stocks')}
                            </p>
                            <ul className="font-16">
                                <li>
                                    S&P 500
                                </li>
                                <li>
                                    DXY
                                </li>
                                <li>
                                    DOW JONES 30
                                </li>
                                <li>
                                    DAX 30
                                </li>
                                <li>
                                    NDX 100
                                </li>
                            </ul>
                        </div>
                        <div className={classes.col}>
                            <p className={[classes.colText, 'font-20-md6'].join(' ')}>
                                {t('home.trade_assets.tokenized_assets')}
                            </p>
                            <ul className="font-16">
                                <li>
                                    {t('home.trade_assets.tokenized_assets.utilitarian_tokens')}
                                </li>
                                <li>
                                    {t('home.trade_assets.tokenized_assets.art_tokens')}
                                </li>
                                <li>
                                    {t('home.trade_assets.tokenized_assets.etf_tokens')}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={classes.buttons}>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.trade')}
                            </Button>
                        </div>
                        <div className={classes.btn}>
                            <Button secondary>
                                {t('btn.learn_more')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div ref={bodyRef} className={classes.imageWrap}>
                    <img ref={imageRef} className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default TradeAssets;
