import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/HomePage/Banner/Banner";
import WhyPlatform from "../../components/HomePage/WhyPlatform/WhyPlatform";
import TradeAssets from "../../components/HomePage/TradeAssets/TradeAssets";
import AccountTypes from "../../components/HomePage/AccountTypes/AccountTypes";
import WideBonus from "../../components/HomePage/WideBonus/WideBonus";
import StartYour from "../../components/HomePage/StartYour/StartYour";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>White Advisory</title>
        <meta name="title" content="Discover trading and security opportunities on White Advisory" />
        <meta property="og:title" content="Discover trading and security opportunities on White Advisory" />
        <meta property="twitter:title" content="Discover trading and security opportunities on White Advisory" />

        <meta name="description" content="Discover trading and security prospects on White Advisory, where you may trade in a safe and secure atmosphere." />
        <meta property="og:description" content="Discover trading and security prospects on White Advisory, where you may trade in a safe and secure atmosphere." />
        <meta property="twitter:description" content="Discover trading and security prospects on White Advisory, where you may trade in a safe and secure atmosphere." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <WhyPlatform />
        <TradeAssets />
        <AccountTypes />
        <WideBonus />
        <StartYour />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
