import React, { useRef, useEffect } from "react";
import classes from "./Cards.module.scss"
import img1 from "../../../assets/img/about/3/1.png"
import img2 from "../../../assets/img/about/3/2.png"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Cards = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: -200,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                x: 200,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.cards, ""].join(" ")} ref={bodyRef}>
            <div className={[classes.cardsBody, ""].join(" ")}>
                <div className={[classes.cardsImg1, ""].join(" ")}
                    ref={cardRef_01}
                >
                    <img src={img1} alt="" />
                </div>
                <div className={[classes.cardsImg2, ""].join(" ")}
                    ref={cardRef_02}
                >
                    <img src={img2} alt="" />
                </div>
                <div className="container">
                    <div className={[classes.cardsRow, ""].join(" ")}>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                +10 000
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_01')}
                            </p>
                        </div>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                $100 000 000
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_02')}
                            </p>
                        </div>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                $450 000
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_03')}
                            </p>
                        </div>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                50+
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_04')}
                            </p>
                        </div>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                $250
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_05')}
                            </p>
                        </div>
                        <div className={[classes.cardsRowColumnn, ""].join(" ")}>
                            <h2 className={[classes.cardsRowColumnnTitle, "font-36"].join(" ")}>
                                3
                            </h2>
                            <p className={[classes.cardsRowColumnnText, "font-16"].join(" ")}>
                                {t('about.cards.text_06')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cards;
