import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountsComponents/Banner/Banner";
import Account from "../../components/AccountsComponents/Account/Account";
import Create from "../../components/AccountsComponents/Create/Create";
import AccountTypes from "../../components/AboutComponents/AccountTypes/AccountTypes";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Account types</title>
        <meta name="title" content="Choose the best account type for your trading goals and preferences on White Advisory, which will provide you with customized tools and benefits to help you succeed." />
        <meta property="og:title" content="Choose the best account type for your trading goals and preferences on White Advisory, which will provide you with customized tools and benefits to help you succeed." />
        <meta property="twitter:title" content="Choose the best account type for your trading goals and preferences on White Advisory, which will provide you with customized tools and benefits to help you succeed." />

        <meta name="description" content="On White Advisory, you may choose from a range of account kinds to improve your trading experience, whether you're a beginner, an experienced trader, or a professional looking for additional features and tools." />
        <meta property="og:description" content="On White Advisory, you may choose from a range of account kinds to improve your trading experience, whether you're a beginner, an experienced trader, or a professional looking for additional features and tools." />
        <meta property="twitter:description" content="On White Advisory, you may choose from a range of account kinds to improve your trading experience, whether you're a beginner, an experienced trader, or a professional looking for additional features and tools." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Account />
        <AccountTypes />
        <Create />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
