import React, { useEffect, useRef } from "react";
import classes from './Protection.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/SecurityPage/protection.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Protection = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef.current, {
                x: 150,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.protection}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t('security.protection.title')}
                    </h2>
                    <div className={[classes.text, 'font-16'].join(' ')}>
                        <p>
                            {t('security.protection.text_01')}
                        </p>
                        <p>
                            {t('security.protection.text_02')}
                        </p>
                        <p>
                            {t('security.protection.text_03')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn.trade')}
                        </Button>
                    </div>
                </div>
                <img ref={imageRef} className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Protection;
