import React from "react";
import classes from './OurComm.module.scss';
import image_01 from '../../../assets/img/SecurityPage/OurComm/image_01.png';
import image_02 from '../../../assets/img/SecurityPage/OurComm/image_02.png';
import image_03 from '../../../assets/img/SecurityPage/OurComm/image_03.png';
import image_04 from '../../../assets/img/SecurityPage/OurComm/image_04.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const OurComm = () => {
    const { t } = useTranslation(null);
    const cards = [
        {
            image: image_01,
            text: 'security.comm.card_01.text',
        },
        {
            image: image_02,
            text: 'security.comm.card_02.text',
        },
        {
            image: image_03,
            text: 'security.comm.card_03.text',
        },
        {
            image: image_04,
            text: 'security.comm.card_04.text',
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.ourComm}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t('security.comm.title')}
                </h2>
                <p className={[classes.text, 'font-16'].join(' ')}>
                    {t('security.comm.text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.cardImage} src={card.image} alt='' />
                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn.trade')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default OurComm;
