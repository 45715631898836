import React, { useRef, useEffect } from "react";
import classes from "./Create.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import img1 from "../../../assets/img/accounts/4/1.png"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Create = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.create, "mt"].join(" ")} ref={bodyRef}>
            <div className={[classes.createBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.createRow, ""].join(" ")}>
                        <div className={[classes.createRowLeft, ""].join(" ")}>
                            <h3 className={[classes.createRowLeftTitle, "font-40"].join(" ")}>
                                {t('account.create.title')}
                            </h3>
                            <p className={[classes.createRowLeftText, "font-16"].join(" ")}>
                                {t('account.create.text')}
                            </p>
                            <div className={[classes.createRowLeftBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn.trade')}
                                </Button>
                            </div>
                        </div>
                        <div className={[classes.createRowRight, ""].join(" ")} ref={cardRef_01}>
                            <img src={img1} alt="Create Account And Start Trading" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
