import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import circle from '../../../assets/img/SecurityPage/Banner/circle.png';
import sphere from '../../../assets/img/SecurityPage/Banner/sphere.png';
import mob from '../../../assets/img/SecurityPage/Banner/mob.png';
import image from '../../../assets/img/SecurityPage/Banner/image.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const circleRef = useRef(null);
    const sphereRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(imageRef.current, {
                y: 0,
                x: 0,
                delay: 0.3,
                duration: 1,
                borderRadius: 0
            });
            gsap.to(circleRef.current, {
                y: 0,
                x: 0,
                delay: 0.4,
                duration: 1,
            });
            gsap.to(sphereRef.current, {
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-48'].join(' ')}>
                            {t('security.banner.title')}
                        </h1>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t('security.banner.text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.trade')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.images}>
                <div ref={imageRef} className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <img ref={circleRef} className={classes.circle} src={circle} alt='' />
                <img ref={sphereRef} className={classes.sphere} src={sphere} alt='' />
            </div>
            <img className={classes.mob} src={mob} alt='' />
        </section>
    );
};

export default Banner;
