import React, { useRef, useEffect } from "react";
import Button from '../../GlobalComponents/Button/Button'
import img1 from "../../../assets/img/bonus/3/1.png"
import classes from "./Requirements.module.scss"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Requirements = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.requirements, "mt"].join(" ")} ref={bodyRef}>
            <div className={[classes.requirementsBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.requirementsRow, ""].join(" ")}>
                        <div className={[classes.requirementsRowLeft, ""].join(" ")}>
                            <h2 className={[classes.requirementsRowLeftTitle, "font-40"].join(" ")}>
                                {t('bonuses.reqs.title')}
                            </h2>
                            <p className={[classes.requirementsRowLeftText, "font-16"].join(" ")}>
                                {t('bonuses.reqs.text_01')}
                            </p>
                            <div className={[classes.requirementsRowLeftRow, ""].join(" ")}>
                                <p className={[classes.requirementsRowLeftRowColumn, "font-20-md6"].join(" ")}>
                                    {t('bonuses.reqs.row_01')}
                                </p>
                                <p className={[classes.requirementsRowLeftRowColumn, "font-20-md6"].join(" ")}>
                                    {t('bonuses.reqs.row_02')}
                                </p>
                                <p className={[classes.requirementsRowLeftRowColumn, "font-20-md6"].join(" ")}>
                                    {t('bonuses.reqs.row_03')}
                                </p>
                                <p className={[classes.requirementsRowLeftRowColumn, "font-20-md6"].join(" ")}>
                                    {t('bonuses.reqs.row_04')}
                                </p>
                            </div>
                            <p className={[classes.requirementsRowLeftText, "font-16"].join(" ")}>
                                {t('bonuses.reqs.text_02')}
                            </p>
                            <div className={[classes.requirementsRowLeftBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn.trade')}
                                </Button>
                            </div>
                        </div>
                        <div className={[classes.requirementsRowRight, ""].join(" ")} ref={cardRef_01}>
                            <img src={img1} alt="Bonus Requirements" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Requirements