import $ from 'jquery';
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./components/GlobalComponents/Router/AppRouter";
import { images } from "./utils/imagesToLoad";
import './App.scss';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]);

  $(document).ready(function () {
    let $cursorX = 0, $cursorY = 0, $top = 0, $left = 0, $leftd = 0, $topd = 0;
    $(document).mousemove(e => {
      $cursorX = e.clientX;
      $cursorY = e.clientY;
    });
    setInterval(() => {
      $leftd += ($cursorX - $leftd);
      $topd += ($cursorY - $topd);
      $('#dot').css({ left: $leftd + 'px', top: $topd + 'px' });
    }, 1);
    setInterval(() => {
      $left += ($cursorX - $left) / 10;
      $top += ($cursorY - $top) / 10;
      $('#cursor').css({ left: $left + 'px', top: $top + 'px' });
    }, 6);

    $('body').on('mouseover', '.hoverElement', function () {
      $("#dot").addClass('cursorDotActive');
      $("#cursor").addClass('cursorCursorActive');
    });
    $('body').on('mouseleave', '.hoverElement', function () {
      $("#dot").removeClass('cursorDotActive');
      $("#cursor").removeClass('cursorCursorActive');
    });

    $('body').on('mouseover', 'a', function () {
      $("#dot").addClass('cursorDotActive');
      $("#cursor").addClass('cursorCursorActive');
    });
    $('body').on('mouseleave', 'a', function () {
      $("#dot").removeClass('cursorDotActive');
      $("#cursor").removeClass('cursorCursorActive');
    });
  });

  return (
    <>
      <div id="dot"></div>
      <div id="cursor"></div>
      <HelmetProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
