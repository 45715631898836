import React, { useRef, useEffect, useState } from "react";
import classes from "./Join.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import img1 from "../../../assets/img/about/4/1.png"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation, Trans } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Join = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                x: 200,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.join, "mt"].join(" ")} ref={bodyRef}>
            <div className={[classes.joinBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.joinRow, ""].join(" ")}>
                        <div className={[classes.joinRowLeft, ""].join(" ")}>
                            <h3 className={[classes.joinRowLeftTitle, "font-40"].join(" ")}>
                                {t('about.join.title')}
                            </h3>
                            <p className={[classes.joinRowLeftText, "font-16"].join(" ")}>
                                <Trans>about.join.text</Trans>
                            </p>
                            <div className={[classes.joinRowLeftBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn.signup')}
                                </Button>
                            </div>
                        </div>
                        <div className={[classes.joinRowRight, ""].join(" ")} ref={cardRef_01}>
                            <img src={img1} alt="Join White Advisory!" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Join;
