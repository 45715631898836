import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";

const Button = ({children, login, href, secondary}) => {
  const { lang } = useParams();
  return (
    <>
      {login ?
        <a href="https://user.white-advisory.net/login" className={`${classes.button}`}>
          <span>{children}</span>
        </a>
        :
        <Link
          to={!href ? 'https:/user.white-advisory.net/signup' : `/${lang}/${href}`} 
          className={`${classes.button} ${secondary && classes.secondary}`}
        >
          <span>{children}</span>
        </Link>
      }
    </>
  );
};

export default Button;
