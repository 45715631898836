import React, { useRef, useEffect } from "react";
import classes from "./Bottom.module.scss"
import img1 from "../../../assets/img/assets/4/1.png"
import Button from '../../GlobalComponents/Button/Button'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Bottom = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.bottom, "pt"].join(" ")} ref={bodyRef}>
            <div className={[classes.bottomBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bottomRow, ""].join(" ")}>
                        <div className={[classes.bottomRowLeft, ""].join(" ")} ref={cardRef_01}>
                            <img src={img1} alt="The Most Popular Pairs On White Advisory" />
                        </div>
                        <div className={[classes.bottomRowRight, ""].join(" ")}>
                            <p className={[classes.bottomRowRightText, "font-16"].join(" ")}>
                                {t('assets.swap.text_01')}
                            </p>
                            <div className={[classes.bottomRowRightTable, ""].join(" ")}>
                                <div className={[classes.bottomRowRightTableHead, ""].join(" ")}>
                                    <p className={[classes.bottomRowRightTableHeadItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.head_01')}
                                    </p>
                                    <p className={[classes.bottomRowRightTableHeadItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.head_02')}
                                    </p>
                                </div>
                                <div className={[classes.bottomRowRightTableRow, ""].join(" ")}>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_01.item_01')}
                                    </p>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_01.item_02')}
                                    </p>
                                </div>
                                <div className={[classes.bottomRowRightTableRow, ""].join(" ")}>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_02.item_01')}
                                    </p>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_02.item_02')}
                                    </p>
                                </div>
                                <div className={[classes.bottomRowRightTableRow, ""].join(" ")}>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_03.item_01')}
                                    </p>
                                    <p className={[classes.bottomRowRightTableRowItem, "font-16"].join(" ")}>
                                        {t('assets.swap.table.row_03.item_02')}
                                    </p>
                                </div>
                            </div>
                            <p className={[classes.bottomRowRightText, "font-16"].join(" ")}>
                                {t('assets.swap.text_02')}
                            </p>
                            <div className={[classes.bottomRowRightBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn.trade')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Bottom