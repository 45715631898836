import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import man from '../../../assets/img/HomePage/Banner/man.png';
import line from '../../../assets/img/HomePage/Banner/line.png';
import items_01 from '../../../assets/img/HomePage/Banner/items_01.png';
import items_02 from '../../../assets/img/HomePage/Banner/items_02.png';
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const imagesRef = useRef(null);
    const manRef = useRef(null);
    const itemsRef_01 = useRef(null);
    const itemsRef_02 = useRef(null);
    const contentRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(contentRef.current, {
                autoAlpha: 1,
                scale: 1,
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.to(imagesRef.current, {
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(manRef.current, {
                scale: 0.9,
                x: 50,
                y: 40,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(itemsRef_01.current, {
                scale: 0.9,
                x: 100,
                y: 10,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(itemsRef_02.current, {
                scale: 0.9,
                x: 70,
                y: 10,
            }, {
                scale: 1,
                x: 0,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div ref={contentRef} className={classes.content}>
                        <h1 className={[classes.title, 'font-64'].join(' ')}>
                            {t('home.banner.title')}
                        </h1>
                        <h2 className={[classes.subtitle, 'font-48'].join(' ')}>
                            {t('home.banner.subtitle')}
                        </h2>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            {t('home.banner.text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn.trade')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={imagesRef} className={classes.images}>
                <img ref={manRef} className={classes.man} src={man} alt='' />
                <img ref={itemsRef_01} className={classes.items_01} src={items_01} alt='' />
                <img ref={itemsRef_02} className={classes.items_02} src={items_02} alt='' />
            </div>
            <img className={classes.line} src={line} alt='' />
        </section>
    );
};

export default Banner;
