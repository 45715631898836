import React, { useRef, useEffect, useState } from "react";
import classes from "./Account.module.scss"
import imgItem1 from '../../../assets/img/accounts/AccountTypes/1.png';
import imgItem2 from '../../../assets/img/accounts/AccountTypes/2.png';
import imgItem3 from '../../../assets/img/accounts/AccountTypes/3.png';
import imgItem4 from '../../../assets/img/accounts/AccountTypes/4.png';
import img1 from "../../../assets/img/accounts/2/i1.png";
import img2 from "../../../assets/img/accounts/2/i2.png";
import img3 from "../../../assets/img/accounts/2/i3.png";
import img4 from "../../../assets/img/accounts/2/i4.png";
import img5 from "../../../assets/img/accounts/2/i5.png";
import img from "../../../assets/img/accounts/2/bg.png";
import Button from '../../GlobalComponents/Button/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Account = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cardRef_05 = useRef(null);
    const cardRef_06 = useRef(null);
    const bodyRef = useRef(null);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 4,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 5,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(cardRef_05.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_06.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 4,
                }
            });
        }
    }, []);

    const [tabs, setTabs] = useState([
        {
            id: 1, img: imgItem1, title: "Basic", text: "accounts.account.card_01.text", active: true
        },
        {
            id: 2, img: imgItem2, title: "Smart Start", text: "accounts.account.card_02.text", active: false
        },
        {
            id: 3, img: imgItem3, title: "Full Access", text: "accounts.account.card_03.text", active: false
        },
        {
            id: 4, img: imgItem4, title: "PRO", text: "accounts.account.card_04.text", active: false
        },
    ]);

    return (
        <div className={[classes.account, "pt"].join(" ")}>
            <div className={[classes.accountBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.accountRow, ""].join(" ")}>
                        <div className={[classes.accountRowLeft, ""].join(" ")}>
                            <h2 className={[classes.accountRowLeftTitle, "font-40"].join(" ")}>
                                {t('accounts.account.title')}
                            </h2>
                            <p className={[classes.accountRowLeftText, "font-16"].join(" ")}>
                                {t('accounts.account.text')}
                            </p>
                            <div className={[classes.accountRowWrap, ""].join(" ")}>
                                <div className={[classes.bannerRowRight, ""].join(" ")}>
                                    <div className={[classes.bannerRowRightContent, ""].join(" ")}>
                                        {tabs.map((i) => {
                                            return (
                                                <div key={i.id} className={i.active ? [classes.bannerRowRightContentTab, classes.active, ""].join(" ") : [classes.bannerRowRightContentTab, ""].join(" ")}>
                                                    <div>
                                                        <h2 className={[classes.bannerRowRightContentTabTitle, "font-32"].join(" ")}>
                                                            {t(i.title)}
                                                        </h2>
                                                        <p className={[classes.bannerRowRightContentTabText, "font-16"].join(" ")}>
                                                            {t(i.text)}
                                                        </p>
                                                    </div>
                                                    <div className={[classes.bannerRowRightContentTabBtn, ""].join(" ")}>
                                                        <Button>
                                                            {t('btn.start')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={[classes.bannerRowRightBtns, ""].join(" ")}>
                                        {tabs.map((i) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setTabs(tabs.map((subI) => {
                                                            if (subI.id === i.id) {
                                                                return { ...subI, active: true }
                                                            } else {
                                                                return { ...subI, active: false }
                                                            }
                                                        }))
                                                    }}
                                                    className={i.active ? [classes.bannerRowRightBtnsBtn, classes.active, ""].join(" ") : [classes.bannerRowRightBtnsBtn, ""].join(" ")}>
                                                    <img src={i.img} alt="" />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.accountRowRight, ""].join(" ")}>
                            <div className={[classes.accountRowRightImgs, ""].join(" ")}>
                                <div className={[classes.accountRowRightImgsImg, ""].join(" ")}
                                    ref={cardRef_01}
                                >
                                    <img src={img} alt="" />
                                </div>
                                <div className={[classes.accountRowRightImgsImg1, ""].join(" ")}
                                    ref={cardRef_02}
                                >
                                    <img src={img1} alt="" />
                                </div>
                                <div className={[classes.accountRowRightImgsImg2, ""].join(" ")}
                                    ref={cardRef_03}
                                >
                                    <img src={img2} alt="" />
                                </div>
                                <div className={[classes.accountRowRightImgsImg3, ""].join(" ")}
                                    ref={cardRef_04}
                                >
                                    <img src={img3} alt="" />
                                </div>
                                <div className={[classes.accountRowRightImgsImg4, ""].join(" ")}
                                    ref={cardRef_05}
                                >
                                    <img src={img4} alt="" />
                                </div>
                                <div className={[classes.accountRowRightImgsImg5, ""].join(" ")}
                                    ref={cardRef_06}
                                >
                                    <img src={img5} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Account;
