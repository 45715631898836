import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Banner.module.scss"
import Button from '../../GlobalComponents/Button/Button';
import img1 from "../../../assets/img/about/1/1.png";
import img2 from "../../../assets/img/about/1/mob.png";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const [width, setWidth] = useState();
    const monetRef1 = useRef(null);
    const monetRef2 = useRef(null);
    const monetRef3 = useRef(null);
    useEffect(() => {
        if (width > 1220) {
            gsap.timeline().to(monetRef1.current, {
                y: 0,
                x: 0,
                delay: 0.3,
                duration: 1,
                borderRadius: 0
            });
            gsap.timeline().to(monetRef2.current, {
                y: 0,
                x: 0,
                delay: 0.4,
                duration: 1,
            });
            gsap.timeline().to(monetRef3.current, {
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
        }
    }, [width]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    return (
        <div className={[classes.banner, ""].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerWrapper, ""].join(" ")}>
                        <h1 className={[classes.bannerTitle, "font-48"].join(" ")}>
                            {t("about.banner.title")}
                        </h1>
                        <p className={[classes.bannerText, "font-16"].join(" ")}>
                            {t("about.banner.text_01")}
                        </p>
                        <p className={[classes.bannerText, "font-16"].join(" ")}>
                            {t("about.banner.text_02")}
                        </p>
                        <div className={[classes.bannerBtn, ""].join(" ")}>
                            <Button>
                                {t('btn.trade')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={[classes.bannerImg, ""].join(" ")}
                    ref={monetRef1}
                >
                    <img src={img1} alt="Diversify Your Portfolio On White Advisory" />
                </div>
                <div className={[classes.bannerCircle, ""].join(" ")}
                    ref={monetRef2}
                >
                </div>
                <div className={[classes.bannerMob, ""].join(" ")}>
                    <img src={img2} alt="Diversify Your Portfolio On White Advisory" />
                </div>
            </div>
        </div>
    );
};

export default Banner;
