import React, { useRef, useEffect } from "react";
import classes from "./Types.module.scss"
import img1 from "../../../assets/img/bonus/2/1.png";
import img2 from "../../../assets/img/bonus/2/2.png";
import img3 from "../../../assets/img/bonus/2/3.png";
import img4 from "../../../assets/img/bonus/2/4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Types = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "bottom bottom",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 180,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "bottom bottom",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "bottom bottom",
                    scrub: 2,
                }
            });
            gsap.timeline().fromTo(cardRef_04.current, {
                y: 100,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "bottom bottom",
                    scrub: 2,
                }
            });


        }
    }, []);
    const columns = [
        {
            id: 1, ref: cardRef_01, img: img1, title: "bonuses.types.card_01.title", desc: "bonuses.types.card_01.text"
        },
        {
            id: 2, ref: cardRef_02, img: img2, title: "bonuses.types.card_02.title", desc: "bonuses.types.card_02.text"
        },
        {
            id: 3, ref: cardRef_03, img: img3, title: "bonuses.types.card_03.title", desc: "bonuses.types.card_03.text"
        },
        {
            id: 4, ref: cardRef_04, img: img4, title: "bonuses.types.card_04.title", desc: "bonuses.types.card_04.text"
        },
    ];
    return (
        <div className={[classes.types, "mt"].join(" ")}>
            <div className={[classes.typesBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.typesTitle, "font-40"].join(" ")}>
                        {t('bonuses.types.title')}
                    </h2>
                    <div className={[classes.typesRow, ""].join(" ")}>
                        {columns.map((i) => {
                            return (
                                <div className={[classes.typesRowColumn, ""].join(" ")} key={i.id}
                                    ref={i.ref}
                                >
                                    <div className={[classes.typesRowColumnItem, ""].join(" ")}>
                                        <div className={[classes.typesRowColumnItemImg, ""].join(" ")}>
                                            <img src={i.img} alt="Four Types of Bonuses on White Advisory" />
                                        </div>
                                        <div className={[classes.typesRowColumnItemContent, ""].join(" ")}>
                                            <h3 className={[classes.typesRowColumnItemContentTitle, "font-24"].join(" ")}>
                                                {t(i.title)}
                                            </h3>
                                            <p className={[classes.typesRowColumnItemContentText, "font-16"].join(" ")}>
                                                {t(i.desc)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={[classes.typesMob, ""].join(" ")}>
                        <div className={classes.swiperWrap}>
                            <Swiper
                                modules={[Pagination]}
                                className="homeWhyPlatformSwiper"
                                spaceBetween={20}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    700: {
                                        slidesPerView: 1,
                                    },
                                    1220: {
                                        slidesPerView: 1,
                                    },
                                }}
                            >
                                {columns.map((card, index) =>
                                    <SwiperSlide key={index} className="homeWhyPlatformSwiperSlide">
                                        <div className={classes.card}>
                                            <h4 className={[classes.cardTitle, 'font-20-md6'].join(' ')}>
                                                {t(card.title)}
                                            </h4>
                                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                                {t(card.desc)}
                                            </p>
                                            <div className={[classes.cardIconWrap, ""].join(" ")}>
                                                <img className={classes.cardIcon} src={card.img} alt='' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </div>
                    <div className={[classes.typesBtn, ""].join(" ")}>
                        <Button>
                            {t('btn.trade')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Types;
