import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AboutComponents/Banner/Banner";
import Tabs from "../../components/AboutComponents/Tabs/Tabs";
import Cards from "../../components/AboutComponents/Cards/Cards";
import Join from "../../components/AboutComponents/Join/Join";
const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>About us</title>
        <meta name="title" content="Explore the full information about White Advisory and start secure and comfortable trading" />
        <meta property="og:title" content="Explore the full information about White Advisory and start secure and comfortable trading" />
        <meta property="twitter:title" content="Explore the full information about White Advisory and start secure and comfortable trading" />

        <meta name="description" content="Get the information that White Advisory is a complete platform that merges the worlds of cryptocurrencies and traditional markets, offering you with diverse options, advanced tools, and a user-friendly interface." />
        <meta property="og:description" content="Get the information that White Advisory is a complete platform that merges the worlds of cryptocurrencies and traditional markets, offering you with diverse options, advanced tools, and a user-friendly interface." />
        <meta property="twitter:description" content="Get the information that White Advisory is a complete platform that merges the worlds of cryptocurrencies and traditional markets, offering you with diverse options, advanced tools, and a user-friendly interface." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Tabs />
        <Cards />
        <Join />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
