import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.svg";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className={[classes.footerWrap, 'mt'].join(' ')}>
      <div className="container">
        <div className={classes.footer}>
          <div className={classes.logo}>
            <img src={logo} alt='' />
          </div>
          <div className={classes.info}>
            <div className={classes.contacts}>
              <a href="tel:+441863440180">
                +441863440180
              </a>
              <a href={`mailto:support@${hostname}`}>
                support@{hostname}
              </a>
              <p>
                Moorcroft, Fore Street, Lifton, Devon, PL16 0BT
              </p>
            </div>
            <div className={classes.docs}>
              <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                {t('footer.docs.terms')}
              </a>
              <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                {t('footer.docs.privacy')}
              </a>
              <a href="/docs/AML_POLICY.pdf" target="_blank">
                {t('footer.docs.aml')}
              </a>
            </div>
          </div>
          <div className={classes.disclaimer}>
            <p className={classes.disclaimerTitle}>
              {t('footer.desclaimer.title')}
            </p>
            <p>
              <Trans>
                {t('footer.desclaimer.text')}
              </Trans>
            </p>
            <p className={classes.num}>
              Company number: 08696513
            </p>
            <p className={classes.rights}>
              {t('footer.rights')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
