import AboutPage from '../pages/AboutPage/AboutPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import AssetPage from '../pages/AssetPage/AssetPage';
import BonusesPage from '../pages/BonusesPage/BonusesPage';
import HomePage from '../pages/HomePage/HomePage';
import SecurityPage from '../pages/SecurityPage/SecurityPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/assets', element: AssetPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/bonuses', element: BonusesPage },
    { path: '/security', element: SecurityPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/assets', element: AssetPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/bonuses', element: BonusesPage },
    { path: '/:lang/security', element: SecurityPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
];


export const headerRoutes = [
    { id: 1, path: 'assets', text: 'header.assets' },
    { id: 2, path: 'accounts', text: 'header.accounts' },
    { id: 3, path: 'bonuses', text: 'header.bonuses' },
    { id: 4, path: 'security', text: 'header.security' },
    { id: 5, path: 'about', text: 'header.about' },
];
