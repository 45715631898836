import React, { useState } from 'react'
import classes from "./Tabs.module.scss"
import img1 from "../../../assets/img/about/2/1.png"
import img2 from "../../../assets/img/about/2/2.png"
import img3 from "../../../assets/img/about/2/3.png"
import img4 from "../../../assets/img/about/2/4.png"
import imgPlus from "../../../assets/img/about/2/plus.png";
import { useTranslation } from 'react-i18next'

const Tabs = () => {
    const { t } = useTranslation();
    const [content, setContent] = useState([
        { id: 1, icon: img1, title: "about.tabs.tab_01.title", text: "about.tabs.tab_01.text", active: true },
        { id: 2, icon: img2, title: "about.tabs.tab_02.title", text: "about.tabs.tab_02.text", active: false },
        { id: 3, icon: img3, title: "about.tabs.tab_03.title", text: "about.tabs.tab_03.text", active: false },
        { id: 4, icon: img4, title: "about.tabs.tab_04.title", text: "about.tabs.tab_04.text", active: false },
    ]);
    const [tab1, setTab1] = useState({ id: 2, title: "about.tabs.tab_02.title" })
    const [tab2, setTab2] = useState({ id: 3, title: "about.tabs.tab_03.title" })
    const [tab3, setTab3] = useState({ id: 4, title: "about.tabs.tab_04.title" })
    return (
        <div className={[classes.block, "mt mb"].join(" ")}>
            <div className={[classes.blockBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.blockRow, ""].join(" ")}>
                        <div className={[classes.blockRowLeft, ""].join(" ")}>
                            {content.map((i) => {
                                if (i.active) {
                                    return (
                                        <div key={i.id} className={[classes.blockRowLeftContent, ""].join(" ")}>
                                            <div className={[classes.blockRowLeftContentIcon, ""].join(" ")}>
                                                <img src={i.icon} alt="About Us" />
                                            </div>
                                            <h2 className={[classes.blockRowLeftContentTitle, "font-20-md4"].join(" ")}>
                                                {t(i.title)}
                                            </h2>
                                            <p className={[classes.blockRowLeftContentText, "font-16"].join(" ")}>
                                                {t(i.text)}
                                            </p>
                                        </div>
                                    )
                                }
                            })}
                        </div>
                        <div className={[classes.blockRowRight, ""].join(" ")}>
                            <div className={[classes.blockRowRightTab, ""].join(" ")}
                                onClick={() => {
                                    let id = "";
                                    let title = "";
                                    content.map((subI) => {
                                        if (subI.active) {
                                            id = subI.id
                                            title = subI.title
                                        }
                                    })
                                    setContent(content.map((subI) => {
                                        if (subI.id === tab1.id) {
                                            return { ...subI, active: true }
                                        } else {
                                            return { ...subI, active: false }
                                        }
                                    }))
                                    setTab1({ id: id, title: title })
                                }}
                            >
                                <p className='font-20-md4'>
                                    {t(tab1.title)}
                                </p>
                                <img src={imgPlus} alt="About Us" />
                            </div>
                            <div className={[classes.blockRowRightTab, ""].join(" ")}
                                onClick={() => {
                                    let id = "";
                                    let title = "";
                                    content.map((subI) => {
                                        if (subI.active) {
                                            id = subI.id
                                            title = subI.title
                                        }
                                    })
                                    setContent(content.map((subI) => {
                                        if (subI.id === tab2.id) {
                                            return { ...subI, active: true }
                                        } else {
                                            return { ...subI, active: false }
                                        }
                                    }))
                                    setTab2({ id: id, title: title })
                                }}
                            >
                                <p className='font-20-md4'>
                                    {t(tab2.title)}
                                </p>
                                <img src={imgPlus} alt="About Us" />
                            </div>
                            <div className={[classes.blockRowRightTab, ""].join(" ")}
                                onClick={() => {
                                    let id = "";
                                    let title = "";
                                    content.map((subI) => {
                                        if (subI.active) {
                                            id = subI.id
                                            title = subI.title
                                        }
                                    })
                                    setContent(content.map((subI) => {
                                        if (subI.id === tab3.id) {
                                            return { ...subI, active: true }
                                        } else {
                                            return { ...subI, active: false }
                                        }
                                    }))
                                    setTab3({ id: id, title: title })
                                }}
                            >
                                <p className='font-20-md4'>
                                    {t(tab3.title)}
                                </p>
                                <img src={imgPlus} alt="About Us" />
                            </div>
                        </div>

                    </div>
                    <div className={[classes.blockFaqMob, ""].join(" ")}>
                        {content.map((i) => {
                            return (
                                <div className={i.active ? [classes.blockFaqMobColumn, classes.active, ""].join(" ") : [classes.blockFaqMobColumn, ""].join(" ")} key={i.id}>
                                    <div className={[classes.blockFaqMobColumnIcon, ""].join(" ")}>
                                        <img src={i.icon} alt="About Us" />
                                    </div>
                                    <div className={[classes.blockFaqMobColumnTitle, ""].join(" ")}
                                        onClick={() => {
                                            setContent(content.map((subI) => {
                                                if (i.id === subI.id) {
                                                    return { ...subI, active: true }
                                                } else {
                                                    return { ...subI, active: false }
                                                }
                                            }))
                                        }}
                                    >
                                        <p className='font-20-md6'>{t(i.title)}</p>
                                        <img src={imgPlus} alt="About Us" />
                                    </div>
                                    <p className={[classes.blockFaqMobColumnText, "font-16"].join(" ")}>
                                        {t(i.text)}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tabs;
