import React, { useRef, useEffect, useState } from "react";
import classes from "./More.module.scss"
import img1 from "../../../assets/img/bonus/4/1.png"
import Button from '../../GlobalComponents/Button/Button'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const More = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 200,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <div className={[classes.more, "mt"].join(" ")} ref={bodyRef}>
            <div className={[classes.moreBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.moreRow, ""].join(" ")}>
                        <div className={[classes.moreRowLeft, ""].join(" ")} ref={cardRef_01}>
                            <img src={img1} alt="Get More Rewards With Bonus System" />
                        </div>
                        <div className={[classes.moreRowRight, ""].join(" ")}>
                            <h3 className={[classes.moreRowRightTitle, "font-40"].join(" ")}>
                                {t('bonuses.rewards.title')}
                            </h3>
                            <p className={[classes.moreRowRightText, "font-16"].join(" ")}>
                                {t('bonuses.rewards.text')}
                            </p>
                            <div className={[classes.moreRowRightBtn, ""].join(" ")}>
                                <Button>
                                    {t('btn.trade')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default More